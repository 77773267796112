import React, {
  useState,
  useEffect
} from "react";
import {
  useHistory,
  useParams
} from "react-router-dom";
import {
  Card
} from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import {
  CRUDLayout,
  Input,
  ActionButton,
  DataStatus,
  DeleteButton,
  Alert,
  SelectSearch,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  BackButton,
  TextArea
} from "components";
import {
  ModalTambahBarang
} from './Section'
import {
  RegisterSalesOrderApi,
  TransaksiSalesOrderApi
} from "api";
import {
  RupiahConvert
} from "utilities";

const CreateSalesOrder = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id_sales_order } = useParams()

  const [isLoading, setIsLoading] = useState(false)
  const [modalBarangConfig, setModalBarangConfig] = useState(false);
  const [data, setData] = useState({})
  const [listBarang, setListBarang] = useState([]);
  const [inputDiskon, setDiskon] = useState(0);
  const [inputPpn, setPpn] = useState(0);
  const [dropdown, setDropdown] = useState({
    customer: [],
    karyawan: []
  })
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    show: false,
    text: "",
  });

  const getData = async () => {
    setIsLoading(true)

    Axios.all([
      TransaksiSalesOrderApi.getSingle(id_sales_order),
      RegisterSalesOrderApi.getSales(),
      RegisterSalesOrderApi.getCustomer()
    ])
      .then(
        Axios.spread((resData, resSales, resCustomer) => {
          const data = resData?.data?.data
          const { detail, diskon, ppn } = data
          const karyawan = resSales?.data?.data?.map((val) => Object({ value: val.id_karyawan, label: val.nama_karyawan }))
          const customer = resCustomer?.data?.data?.map((val) => Object({
            value: val.id_customer,
            label: val.nama_customer,
            alamat: val.alamat,
            full_address: `${val.alamat}, ${val.nama_desa}, ${val.nama_kecamatan}, ${val.nama_kabupaten}, ${val.nama_provinsi}`
          }))

          setData(data);
          setListBarang(detail?.map((val) => Object({
            ...val,
            harga_terbaru: val.harga_satuan_jual,
            qty_item: parseFloat(val.qty_item ?? 0),
            sub_total: parseInt(val.harga_satuan_jual ?? 0) * parseFloat(val.qty_item ?? 0)
          })));
          setDropdown({ customer, karyawan })
          setDiskon(parseFloat(diskon ?? 0))
          setPpn(parseFloat(ppn ?? 0))
        })
      )
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false))
  };

  useEffect(() => {
    setNavbarTitle("Sales Order")
    getData();
  }, []);

  // Hitung total 
  const hitung = () => {
    let total = 0;
    let jmlDiskon = 0;
    let jmlPpn = 0;
    let totalSetelahDiskon = 0;
    let totalSetelahPpn = 0;

    if (listBarang.length > 0) {
      total = listBarang.reduce((sum, { sub_total }) => sum + parseInt(sub_total), 0);
      jmlDiskon = (total * inputDiskon ?? 0) / 100;
      totalSetelahDiskon = total - jmlDiskon;
      jmlPpn = (totalSetelahDiskon * inputPpn ?? 0) / 100;
      totalSetelahPpn = totalSetelahDiskon + jmlPpn;
    }

    return {
      total,
      jmlDiskon,
      jmlPpn,
      totalSetelahDiskon,
      totalSetelahPpn
    }
  };

  const formInitialValues = {
    id_sales_order: id_sales_order,
    tgl_sales_order: data?.tgl_sales_order ?? '',
    batas_waktu: data?.batas_waktu ?? '',
    no_sales_order: data?.no_sales_order ?? '',
    id_karyawan: data?.id_sales ?? '',
    id_customer: data?.id_customer ?? '',
    alamat_pengiriman: data?.alamat_pengiriman ?? '',
    catatan: data?.catatan ?? '',
  };

  const formValidationSchema = Yup.object().shape({
    id_karyawan: Yup.string().required("Pilih Sales"),
    id_customer: Yup.string().required("Pilih Customer"),
    alamat_pengiriman: Yup.string().required("Masukkan Alamat Customer"),
    catatan: Yup.string().required("Tambahkan Catatan"),
  });

  const formSubmitHandler = (values, { setSubmitting }) => {
    if (listBarang.length > 0) {
      let finalListBarang = [];

      // Map List Barang sebelum submit ke server
      listBarang.map((obj) =>
        finalListBarang.push({
          id_barang: obj.id_barang,
          id_gudang: obj.id_gudang,
          qty_item: obj.qty_item,
          harga_satuan_jual: parseInt(obj.harga_terbaru ?? 0),
        })
      );

      const finalValues = {
        ...values,
        diskon: inputDiskon,
        ppn: inputPpn,
        detail: finalListBarang,
      };

      TransaksiSalesOrderApi.updateSalesOrder(finalValues)
        .then(() => history.push("/transaksi/sales-order", {
          registerAlertConfig: {
            variant: "primary",
            show: true,
            text: "Ubah data berhasil!"
          }
        }))
        .catch((err) => setAlertConfig({
          variant: "danger",
          show: true,
          text: `Ubah data gagal! (${err.response.data.message})`
        }))
        .finally(() => setSubmitting(false));

    } else {
      setSubmitting(false);

      setAlertConfig({
        variant: "danger",
        show: true,
        text: 'List Barang tidak boleh kosong!',
      });
    }
  };

  return (
    <React.Fragment>
      <CRUDLayout>
        <Alert
          show={alertConfig.show}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setAlertConfig((prev) => ({ ...prev, show: false }))}
        />

        <Card className="pb-3 mt-3">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Ubah Data Sales Order</b>
            <BackButton size="sm" onClick={() => history.goBack()} />
          </Card.Header>

          {!isLoading ?
            <Card.Body>
              <Formik
                enableReinitialize
                initialValues={formInitialValues}
                validationSchema={formValidationSchema}
                onSubmit={formSubmitHandler}
              >
                {({ values, errors, touched, isSubmitting, handleChange, handleSubmit, setFieldValue, setSubmitting }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-3 col-sm-12">
                          <Input
                            label="Tgl. Sales Order"
                            type="date"
                            name="tgl_sales_order"
                            value={values.tgl_sales_order}
                            onChange={async (e) => {
                              const value = e.target.value;
                              setFieldValue("tgl_sales_order", value);
                            }}
                            error={errors.tgl_sales_order && touched.tgl_sales_order && true}
                            errorText={errors.tgl_sales_order}
                          />

                          <Input
                            label="No. Sales Order"
                            type="text"
                            name="no_sales_order"
                            placeholder="No. Sales Order"
                            readOnly={true}
                            value={values.no_sales_order}
                            error={errors.no_sales_order && touched.no_sales_order && true}
                            errorText={errors.no_sales_order}
                          />

                          <Input
                            label="Batas Waktu Pengiriman"
                            type="date"
                            name="batas_waktu"
                            value={values.batas_waktu}
                            onChange={(e) => {
                              const value = e.target.value;
                              setFieldValue("batas_waktu", value);
                            }}
                            error={errors.batas_waktu && touched.batas_waktu && true}
                            errorText={errors.batas_waktu}
                          />
                        </div>

                        <div className="col-md-4 col-sm-12">
                          <SelectSearch
                            label="Sales"
                            name="id_karyawan"
                            placeholder="Pilih Sales"
                            onChange={(val) => setFieldValue("id_karyawan", val.value)}
                            option={dropdown?.karyawan ?? []}
                            defaultValue={values.id_karyawan ? dropdown?.karyawan?.find((kar) => kar.value === values.id_karyawan) : ''}
                            error={errors.id_karyawan && touched.id_karyawan && true}
                            errorText={errors.id_karyawan && touched.id_karyawan && errors.id_karyawan}
                          />

                          <div className="row">
                            <div className="col-md-12">
                              <SelectSearch
                                label="Customer"
                                name="id_customer"
                                placeholder="Pilih Customer"
                                option={dropdown?.customer ?? []}
                                onChange={(val) => {
                                  setFieldValue("id_customer", val.value);
                                  setFieldValue("alamat_pengiriman", val.full_address);
                                }}
                                defaultValue={values?.id_customer ? dropdown?.customer.find((cus) => cus.value === values.id_customer) : ""}
                                error={errors.id_customer && touched.id_customer && true}
                                errorText={errors.id_customer && touched.id_customer && errors.id_customer}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-5 col-sm-12">
                          <TextArea
                            label="Alamat Pengiriman"
                            placeholder="Masukkan Alamat Pengiriman"
                            name="alamat_pengiriman"
                            value={values.alamat_pengiriman}
                            onChange={handleChange}
                            error={errors.alamat_pengiriman && touched.alamat_pengiriman && true}
                            errorText={errors.alamat_pengiriman}
                            rows={4}
                          />
                        </div>
                      </div>

                      <hr className="mb-3" />

                      <div className="row mx-1 mb-2 d-flex justify-content-between align-items-end">
                        <small>List Barang</small>
                        <ActionButton text='Tambah Barang' onClick={() => setModalBarangConfig(true)} />
                      </div>

                      <CRUDLayout.Table>
                        <THead>
                          <tr className="text-center bg-light">
                            <ThFixed>No</ThFixed>
                            <ThFixed>Kode Barang</ThFixed>
                            <Th>Item Barang</Th>
                            <Th>Gudang</Th>
                            <Th style={{ width: 100 }}>Qty. SO</Th>
                            <Th style={{ width: 100 }}>Satuan Jual</Th>
                            <Th style={{ width: 150 }}>Harga Satuan Jual</Th>
                            <Th style={{ width: 150 }}>Sub Total</Th>
                            <ThFixed>Aksi</ThFixed>
                          </tr>
                        </THead>
                        <TBody>
                          {listBarang?.map((item, index) => {
                            return (
                              <Tr key={index}>
                                <TdFixed>{index + 1}</TdFixed>
                                <TdFixed>{item.kode_barang}</TdFixed>
                                <Td>{item.nama_barang}</Td>
                                <Td>{item.nama_gudang}</Td>
                                <Td textRight>{item.qty_item}</Td>
                                <Td>{item.nama_satuan}</Td>
                                <Td textRight>{item.harga_terbaru ? RupiahConvert(parseInt(item.harga_terbaru).toString()).detail : "Rp. 0"}
                                </Td>
                                <Td textRight>
                                  {item.sub_total
                                    ? RupiahConvert(parseInt(item.sub_total).toString()).detail
                                    : "Rp. 0"}
                                </Td>
                                <TdFixed>
                                  <div className="d-flex justify-content-center">
                                    <DeleteButton
                                      onClick={() => {
                                        let filteredBarang = listBarang.filter((e, e_index) => e_index !== index);
                                        setListBarang(filteredBarang)
                                      }}
                                    />
                                  </div>
                                </TdFixed>
                              </Tr>
                            );
                          })}

                          {/* Total */}
                          <Tr>
                            <Td colSpan={7} textRight className="align-middle py-2 font-weight-bold">Total</Td>
                            <Td textRight className="align-middle py-2 font-weight-bold">{hitung().total ? RupiahConvert(String(parseInt(hitung().total))).detail : "Rp. 0"}</Td>
                            <Td />
                          </Tr>

                          {/* Diskon */}
                          <Tr>
                            <Td colSpan={7} textRight className="align-middle font-weight-bold">
                              <div className="d-flex justify-content-end align-items-center">
                                Diskon
                                <input
                                  className="form-control form-control-sm text-right mx-2"
                                  style={{ width: '4rem', maxWidth: "6rem" }}
                                  placeholder="0 %"
                                  type="number"
                                  name="diskon"
                                  defaultValue={inputDiskon}
                                  max={99}
                                  min={0}
                                  onChange={(e) => {
                                    let { value } = e.target;
                                    if (e.target.value > 0 && e.target.value <= 99) {
                                      setDiskon(value);
                                    } else if (e.target.value > 99) {
                                      setDiskon(99);
                                      e.target.value = 99;
                                    } else {
                                      setDiskon(0);
                                    }
                                  }}
                                />
                                %
                              </div>
                            </Td>
                            <Td textRight className="align-middle font-weight-bold">{hitung().jmlDiskon ? RupiahConvert(String(parseInt(hitung().jmlDiskon))).detail : "Rp. 0"}</Td>
                            <Td />
                          </Tr>

                          {/* Total Setelah Diskon */}
                          <Tr>
                            <Td colSpan={7} textRight className="align-middle py-2 font-weight-bold">Total Setelah Diskon</Td>
                            <Td textRight className="align-middle py-2 font-weight-bold">{hitung().totalSetelahDiskon ? RupiahConvert(String(parseInt(hitung().totalSetelahDiskon))).detail : "Rp. 0"}</Td >
                            <Td />
                          </Tr>

                          {/* PPN */}
                          <Tr>
                            <Td colSpan={7} textRight className="align-middle font-weight-bold">
                              <div className="d-flex justify-content-end align-items-center">
                                PPN
                                <input
                                  className="form-control form-control-sm text-right mx-2"
                                  style={{ width: '4rem', maxWidth: "6rem" }}
                                  placeholder="0 %"
                                  type="number"
                                  name="ppn"
                                  defaultValue={inputPpn}
                                  max={99}
                                  min={0}
                                  onChange={(e) => {
                                    let { value } = e.target;
                                    if (e.target.value > 0 && e.target.value <= 99) {
                                      setPpn(value);
                                    } else if (e.target.value > 99) {
                                      setPpn(99);
                                      e.target.value = 99;
                                    } else {
                                      setPpn(0);
                                    }
                                  }}
                                />
                                %
                              </div>
                            </Td>
                            <Td textRight className="align-middle font-weight-bold">{hitung().jmlPpn ? RupiahConvert(String(parseInt(hitung().jmlPpn))).detail : "Rp. 0"}</Td>
                            <Td />
                          </Tr>

                          {/* Total Setelah PPN */}
                          <Tr>
                            <Td colSpan={7} textRight className="align-middle py-2 font-weight-bold">Total Setelah PPn</Td>
                            <Td textRight className="align-middle py-2 font-weight-bold">{hitung().totalSetelahPpn ? RupiahConvert(String(parseInt(hitung().totalSetelahPpn))).detail : "Rp. 0"}</Td>
                            <Td />
                          </Tr>
                        </TBody>
                      </CRUDLayout.Table>

                      <TextArea
                        label='Catatan Sales Order'
                        name='catatan'
                        value={values.catatan}
                        rows={5}
                        onChange={handleChange}
                        error={Boolean(errors.catatan && touched.catatan)}
                        errorText={errors.catatan}
                      />

                      <div className="d-flex justify-content-end">
                        <ActionButton
                          type="submit"
                          variant="success"
                          text="Simpan"
                          className="mt-2 px-4"
                          loading={isSubmitting}
                        />
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </Card.Body>
            : <DataStatus loading={isLoading} text='Memuat Data . . .' />
          }
        </Card>
      </CRUDLayout>

      {modalBarangConfig && (
        <ModalTambahBarang
          listBarang={listBarang}
          setListBarang={setListBarang}
          modalBarangConfig={modalBarangConfig}
          setModalBarangConfig={setModalBarangConfig}
        />
      )}
    </React.Fragment >
  )
}

export default CreateSalesOrder;
