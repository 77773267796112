import {
  useState,
  useEffect
} from 'react'
import {
  Row,
  Col
} from 'react-bootstrap'
import {
  DatePicker,
  Input
} from 'components'
import {
  DateConvert,
} from 'utilities'
import {
  FakturPenjualanApi
} from 'api'

export const FormSection = ({formik, fetchingStatus, formRendered, setFormRendered}) => {
  const today = DateConvert(new Date()).default
  const [isNomorLoading, setIsNomorLoading] = useState(false)
  const {values, setValues} = formik
  
  const getNomorHandler = date => {
    setIsNomorLoading(true)
    
    FakturPenjualanApi.getNomor({tanggal: date})
      .then(({data}) => {
        const nomor = data.data

        setValues({
          ...values,
          nomor: nomor,
          tanggal: date
        })
      })
      .catch(() => {
        setValues({
          ...values,
          nomor: "",
          tanggal: ""
        })
        window.alert("Nomor gagal dimuat")
      })
      .finally(() => {
        setIsNomorLoading(false)
      })
  }

  useEffect(() => {
    getNomorHandler(today)

    return () => {
      setFormRendered(formRendered + 1)
      setIsNomorLoading(false)
    }
  }, [])
  
  return (
    <Row>
      <Col md={8} lg={6}>
        <Row>
          <Col md>
            <DatePicker 
              label="Tgl. Faktur Penjualan"
              placeholderText="Pilih tanggal"
              selected={values.tanggal ? new Date(values.tanggal) : ""}
              onChange={date => {
                const newDate = DateConvert(date).default
                getNomorHandler(newDate)
              }}
              />
          </Col>
          <Col md>
            <Input 
              readOnly
              label="No. Faktur penjualan"
              placeholder={isNomorLoading ? "Memuat nomor . . ." : "Pilih tanggal untuk menentukan nomor"}
              value={isNomorLoading ? "Memuat nomor . . ." : values.nomor}
              />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}