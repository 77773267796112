import { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import { ActionButton, Alert, BackButton, DataStatus } from "components";
import { FakturPenjualanApi } from "api";
import { TableSection } from "./Section";
import { InfoSection } from '../Section'
import ReactToPrint from "react-to-print";
import PrintFakturPenjualan from "./PrintFakturPenjualan";

const DetailFakturPenjualan = ({ setNavbarTitle }) => {
  const { id } = useParams();
  const history = useHistory();
  const componentRef = useRef();
  const [dataInfo, setDataInfo] = useState({});
  const [dataTable, setDataTable] = useState([]);
  const [dataStakeholder, setDataStakeholder] = useState([]);
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: false,
    success: true,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const mappingDataInfo = (data) => {
    return {
      id_sales_order: data.id_sales_order ?? "-",
      tgl_sales_order: data.tgl_sales_order ?? null,
      no_sales_order: data.no_sales_order ?? "-",
      tgl_faktur_penjualan: data.tgl_faktur ?? null,
      no_faktur_penjualan: data.no_faktur ?? "-",
      customer: data.nama_customer ?? "-",
      sales: data.nama_sales ?? "-",
      diskon: data.diskon ? parseInt(data.diskon) : 0,
      ppn: data.ppn ? parseInt(data.ppn) : 0,
      nama_proyek: data.nama_proyek ?? "-",
    };
  };

  const mappingDataTable = (data) =>
    data.map((val) => {
      return {
        id_surat_jalan: val.id_surat_jalan ?? null,
        no_penerimaan_surat_jalan: val?.no_penerimaan_surat_jalan ?? null,
        tgl_penerimaan_surat_jalan: val?.tgl_penerimaan_surat_jalan ?? null,
        tgl_surat_jalan: val.tgl_surat_jalan ?? null,
        no_surat_jalan: val.no_surat_jalan ?? "-",
        no_delivery_order: val.no_delivery_order ?? "-",
        nama_item: val.nama_item ?? "-",
        satuan: val.nama_satuan ?? "",
        qty: val.qty ? parseFloat(val.qty) : 0,
        harga: val.harga_satuan_jual ? parseInt(val.harga_satuan_jual) : 0,
        checked: false,
      };
    });

  const getInitialData = () => {
    setFetchingStatus({
      loading: true,
      success: false,
    });

    FakturPenjualanApi.getSingleFaktur({ id_faktur_penjualan: id })
      .then(({ data }) => {
        const getData = data.data;
        const mapDataInfo = getData ?? {};
        const mapDataTable = mappingDataTable(getData.detail ?? []);
        const getDataStakeholder = data.data.stakeholder;

        setDataInfo(mapDataInfo);
        setDataTable(mapDataTable);
        setDataStakeholder(getDataStakeholder);
        setFetchingStatus({
          loading: false,
          success: true,
        });
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "primary",
          text: "Data gagal dimuat!",
        });
        setFetchingStatus({
          loading: false,
          success: false,
        });
      });
  };

  useEffect(() => {
    getInitialData();
    setNavbarTitle("Faktur Penjualan");
  }, []);

  const CatatanApprovalCard = () => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Catatan Approval Surat Jalan</b>
        </Card.Header>
        <Card.Body>
          {dataStakeholder.length > 1 ? (
            <Row>
              {dataStakeholder.map(
                (val, index) =>
                  index !== 0 &&
                  val.status_approval !== "PEN" && (
                    <Col sm>
                      <InfoItem
                        title1={
                          val.status_approval === "VER" || val.status_approval === "REV"
                            ? `Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}`
                            : val.status_approval === "APP"
                            ? "Pengesah"
                            : "Di Tolak Oleh"
                        }
                        value1={val.nama_karyawan ?? "-"}
                        title2="Catatan"
                        value2={val.catatan ?? "-"}
                      />
                    </Col>
                  )
              )}
            </Row>
          ) : (
            <div className="d-flex justify-content-center">
              <DataStatus text="Tidak ada catatan" />
            </div>
          )}
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      <Card style={{ minHeight: "80vh" }}>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Faktur Penjualan</b>
          <div>
            <ReactToPrint
              trigger={() => <ActionButton className="mb-1 mx-2" text="Cetak" size="sm" />}
              content={() => componentRef.current}
            />
            <BackButton size="sm" onClick={() => history.goBack()} />
          </div>
        </Card.Header>
        <Card.Body className="pb-5">
          <Alert
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            showCloseButton={true}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {fetchingStatus.loading ? (
            <DataStatus loading text="Memuat . . ." />
          ) : fetchingStatus.success ? (
            <>
              <InfoSection data={dataInfo} type="detail" />
              <TableSection dataInfo={dataInfo} dataTable={dataTable} setDataTable={setDataTable} />
            </>
          ) : (
            <DataStatus text="Data gagal dimuat" />
          )}
        </Card.Body>
      </Card>
      {!fetchingStatus.loading && fetchingStatus.success && <CatatanApprovalCard />}
      {!fetchingStatus.loading && fetchingStatus.success && (
        <div style={{ display: "none" }}>
          <PrintFakturPenjualan ref={componentRef} dataInfo={dataInfo} dataTable={dataTable} />
        </div>
      )}
    </>
  );
};

export default DetailFakturPenjualan;
