import React, { 
  useState, 
  useEffect 
} from "react"
import { 
  useHistory, 
  useLocation 
} from "react-router-dom"
import { 
  Row, 
  Col 
} from "react-bootstrap"
import {
  IoAdd,
  IoEyeOutline
} from 'react-icons/io5'
import _ from "lodash"
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  UpdateButton,
  ReadButton,
  InputSearch,
  Pagination,
  Table,
  Th,
  Td,
  ThFixed,
  TdFixed,
  BackButton
} from "components"
import { 
  DateConvert, 
  TableNumber,
  RupiahConvert
} from "utilities"
import { 
  FakturPenjualanApi
} from "api"

const ListSalesOrderFakturPenjualan = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [dataSalesOrderFakturPenjualan, setDataSalesOrderFakturPenjualan] = useState([])
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  })
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })

  const getInitialData = () => {
    setIsPageLoading(true)

    FakturPenjualanApi.getSO({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        setDataSalesOrderFakturPenjualan(data.data)
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        })
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      })
      .finally(() => setIsPageLoading(false))
  }

  const checkAlert = () => {
    const locationState = location.state

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        })
      }
    }
  }

  const onInputSearchChange = (e) => {
    const key = e.target.value

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    })
    setPaginationConfig({
      page: "1",
      dataLength: "10",
      totalPage: "1",
      dataCount: "0",
    })
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    })
  }

  useEffect(() => {
    setNavbarTitle("Faktur Penjualan")
    getInitialData()
    checkAlert()

    return () => {
      setIsPageLoading(false)
    }
  }, [
    setNavbarTitle,
    searchConfig.key,
    paginationConfig.page,
    paginationConfig.dataLength,
  ])

  const PageContent = () => {
    const DataTable = () => (
      <Table hover={false}>
        <thead>
          <tr>
            <ThFixed>No</ThFixed>
            <Th>No. Sales Order</Th>
            <Th style={{minWidth: 200}}>Customer</Th>
            <ThFixed>Tgl. Penerimaan Surat Jalan</ThFixed>
            <Th>No. Penerimaan Surat Jalan</Th>
            <Th>No. Surat Jalan</Th>
            <Th style={{minWidth: 200}}>Item Barang</Th>
            <Th style={{minWidth: 100}}>Jumlah</Th>
            <ThFixed>Aksi</ThFixed>
          </tr>
        </thead>
        <tbody>
          {dataSalesOrderFakturPenjualan.map((val, index) => {
            const rowSpan = val?.detail_sj?.length ?? 1

            return (
              <>
                <tr key={index}>
                  <TdFixed rowSpan={rowSpan}>{TableNumber(paginationConfig.page, paginationConfig.dataLength, index)}</TdFixed>
                  <Td rowSpan={rowSpan}>{val.no_sales_order ?? "-"}</Td>
                  <Td rowSpan={rowSpan}>{val.nama_customer ?? "-"}</Td>
                  <Td>{val?.detail_sj[0] ? val.detail_sj[0].tgl_penerimaan_surat_jalan ? DateConvert(new Date(val.detail_sj[0].tgl_penerimaan_surat_jalan)).defaultDMY : "-" : "-"}</Td>
                  <Td>{val?.detail_sj[0] ? val.detail_sj[0].no_penerimaan_surat_jalan ?? '-' : '-'}</Td>
                  <Td>{val?.detail_sj[0] ? val.detail_sj[0].no_surat_jalan ?? '-' : '-'}</Td>
                  <Td>{val?.detail_sj[0] ? val.detail_sj[0].nama_barang ?? '-' : '-'}</Td>
                  <Td textRight>{val?.detail_sj[0] ? RupiahConvert(String(parseInt(val?.detail_sj[0]?.harga_satuan_jual ?? 0) * parseInt(val?.detail_sj[0]?.qty ?? 0))).detail ?? '-' : '-'}</Td>
                  <Td rowSpan={rowSpan}>
                    <ActionButton 
                      size="sm"
                      className="text-nowrap"
                      onClick={() => history.push('/transaksi/faktur-penjualan/tambah/' + val.id_sales_order)}
                    >
                      <IoAdd />
                    </ActionButton>
                  </Td>
                </tr>
                {rowSpan > 1 && val?.detail_sj?.map((valSJ, i) => i !== 0 &&
                  <tr>
                    <Td>{valSJ?.tgl_penerimaan_surat_jalan ? DateConvert(new Date(valSJ?.tgl_penerimaan_surat_jalan)).defaultDMY : "-"}</Td>
                    <Td>{valSJ?.no_penerimaan_surat_jalan ?? '-'}</Td>
                    <Td>{valSJ?.no_surat_jalan ?? '-'}</Td>
                    <Td>{valSJ?.nama_barang ?? '-'}</Td>
                    <Td textRight>{RupiahConvert(String(parseInt(valSJ?.harga_satuan_jual ?? 0) * parseInt(valSJ?.qty ?? 0))).detail}</Td>
                  </tr>
                )}
              </>
            )
          })}
        </tbody>
      </Table>
    )

    if (!dataSalesOrderFakturPenjualan || dataSalesOrderFakturPenjualan.length < 1) {
      return <DataStatus text="Tidak ada data" />
    }

    return (
      <>
        <div className="p-2">
          <b>List Data Penerimaan Surat Jalan Yang Siap Dibuatkan Faktur Penjualan</b>
        </div>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength - paginationConfig.dataLength + 1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    )
  }

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch value={searchConfig.key} onChange={onInputSearchChange} />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Content */}
      {isPageLoading ? <DataStatus loading={true} text="Memuat data . . ." /> : <PageContent />}
    </CRUDLayout>
  )
}

export default ListSalesOrderFakturPenjualan
