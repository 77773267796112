import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { Dropdown, Spinner } from "react-bootstrap"
import FileSaver from "file-saver"
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ReadButton,
  InputSearch,
  Pagination,
  Table,
  Th,
  Td,
  ThFixed,
  TdFixed,
  CreateButton,
  FilterButton
} from "components"
import {
  DateConvert,
  RupiahConvert,
  TableNumber
} from "utilities"
import { PenjualanLangsungApi } from "api"
import ModalFilter from "./components/ModalFilter"

const ListPenjualanLangsung = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [loadingExport, setLoadingExport] = useState(false)
  const [data, setData] = useState([])
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  })
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })
  const SESSION_KEY = {
    FILTER: `${location.pathname}_filter`,
    PAGINATION: `${location.pathname}_pagination`
  }
  const filter = sessionStorage.getItem(SESSION_KEY.FILTER) ? JSON.parse(sessionStorage.getItem(SESSION_KEY.FILTER)) : {}
  const pagination = sessionStorage.getItem(SESSION_KEY.PAGINATION) ? JSON.parse(sessionStorage.getItem(SESSION_KEY.PAGINATION)) : {}
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "detail",
    data: {}
  })
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: filter?.active,
      tgl_penjualan_langsung_mulai: filter?.tgl_penjualan_langsung_mulai,
      tgl_penjualan_langsung_selesai: filter?.tgl_penjualan_langsung_selesai,
      customer: filter?.customer,
      petugas: filter?.petugas,
      id_barang: filter?.id_barang,
      metode_pembayaran: filter?.metode_pembayaran,
    },
    pagination: {
      page: pagination?.page ?? "1",
      dataLength: pagination?.dataLength ?? "10",
      totalPage: pagination?.totalPage ?? "1",
      dataCount: pagination?.dataCount ?? "0",
    }
  })

  const getInitialData = () => {
    setIsPageLoading(true)

    PenjualanLangsungApi.get({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      ...dataFilter?.filter
    })
      .then(({ data }) => {
        setData(data.data)
        setDataFilter(prev => ({
          ...prev,
          pagination: {
            ...prev.pagination,
            dataCount: data.data_count,
            totalPage: data.total_page,
          }

        }))
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      })
      .finally(() => setIsPageLoading(false))
  }

  const checkAlert = () => {
    const locationState = location.state

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        })
      }
    }
  }

  const dismissAlert = () => {
    history.replace("/transaksi/penjualan-langsung", { alert: undefined })
    setAlertConfig((prev) => ({ ...prev, show: false }))
  }

  const onInputSearchChange = (e) => {
    const key = e.target.value

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    })
    setPaginationConfig({
      page: "1",
      dataLength: "10",
      totalPage: "1",
      dataCount: "0",
    })
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    })
  }

  const handleExport = tipe => {
    setLoadingExport(true)

    PenjualanLangsungApi.export({
      ...dataFilter.filter,
      active: undefined,
      tipe,
    })
      .then(res => {
        const data = res.data.data                               // default return http protocol from server
        const name = res.data.data.split('/').pop()

        const generateURL = (location.protocol === "http:") ? data : data.replace("http:", "https:")
        FileSaver.saveAs(generateURL, name)
      }).catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Export Data gagal",
        })
      }).finally(() => setLoadingExport(false))
  }

  useEffect(() => {
    setNavbarTitle("Penjualan Langsung")
    getInitialData()
    checkAlert()

    return () => {
      setIsPageLoading(false)
    }
  }, [setNavbarTitle, searchConfig.key, dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, dataFilter?.filter])

  const PageContent = () => {
    const ItemCollapse = ({ data }) => {
      const [isReadMore, setIsReadMore] = useState(false)

      return (
        <div>
          <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
            {data.map((brg, index) => {
              const barang = `${brg.nama_item} ${brg.qty ? parseFloat(brg.qty ?? 0) : ''} ${brg.nama_satuan ?? ""}`
              if (isReadMore) {
                return <li key={index} index={index}>{brg.nama_item ? barang : ""}</li>
              }
              return index < 2 && <li key={index} index={index}>{brg.nama_item ? barang : ""}</li>
            })}
          </ul>

          {data.length > 2 && (
            <div
              className="text-primary"
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => setIsReadMore((prev) => !prev)}>
              {isReadMore ? "Sembunyikan" : "Selengkapnya..."}
            </div>
          )}
        </div>
      )
    }

    const DataTable = () => (
      <>
        <Table hover={false}>
          <thead>
            <tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Informasi Penjualan Langsung</ThFixed>
              <Th>Customer</Th>
              <Th>Petugas Sales</Th>
              <Th>List Barang</Th>
              <Th>Total Harga</Th>
              <ThFixed>Metode Pembayaran</ThFixed>
            </tr>
          </thead>
          <tbody>
            {data?.map((val, index) => {
              return (
                <tr key={index}>
                  <TdFixed>{TableNumber(paginationConfig.page, paginationConfig.dataLength, index)}</TdFixed>
                  <TdFixed>
                    <ReadButton onClick={() => {
                      //  STORE STATE TO SESSION
                      sessionStorage.setItem(SESSION_KEY.FILTER, JSON.stringify(dataFilter?.filter))
                      sessionStorage.setItem(SESSION_KEY.PAGINATION, JSON.stringify(dataFilter?.pagination))

                      history.push(`/transaksi/penjualan-langsung/detail/${val.id_penjualan_langsung}`)
                    }} />
                  </TdFixed>
                  <Td>
                    <div>{val.tgl_penjualan_langsung ? DateConvert(new Date(val.tgl_penjualan_langsung)).defaultDMY : "-"}</div>
                    <div>{val.no_penjualan_langsung ?? "-"}</div>
                  </Td>
                  <Td>{val.nama_customer ?? ""}</Td>
                  <Td>{val.nama_karyawan ?? ""}</Td>
                  <Td>{
                    val?.detail_penjualan_langsung
                      ? (<ItemCollapse data={val.detail_penjualan_langsung} />)
                      : "-"
                  }</Td>
                  <Td textRight>{RupiahConvert(String(parseInt(val.total_harga ?? 0))).detail}</Td>
                  <Td><span className="text-capitalize">{val.metode_pembayaran}</span></Td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        <Pagination
          dataLength={dataFilter?.pagination?.dataLength}
          dataNumber={dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1}
          dataPage={dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength ? dataFilter?.pagination?.dataCount : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength}
          dataCount={dataFilter?.pagination?.dataCount}
          currentPage={dataFilter?.pagination?.page}
          totalPage={dataFilter?.pagination?.totalPage}
          onPaginationChange={({ selected }) => setDataFilter(prev => ({ ...prev, pagination: { ...prev.pagination, page: selected + 1 } }))}
          onDataLengthChange={(e) => setDataFilter(prev => ({ ...prev, pagination: { page: 1, dataLength: e.target.value } }))}
        />
      </>
    )

    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />
    }

    return (
      <>
        <div className="py-2">
          <b>List Data Penjualan Langsung</b>
        </div>
        <DataTable />
      </>
    )
  }

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch value={searchConfig.key} onChange={onInputSearchChange} />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setModalConfig({ show: true, type: 'filter' })}
            />
            <Dropdown>
              <Dropdown.Toggle className="ml-2 mb-2 px-3 btn btn-warning text-white" disabled={loadingExport}> {loadingExport ? <Spinner animation="border" variant="white" size="sm" /> : "Export"} </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleExport("excel")}>
                  Excel (.xlsx)
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleExport("pdf")}>
                  PDF (.pdf)
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton onClick={() => {
            //  STORE STATE TO SESSION
            sessionStorage.setItem(SESSION_KEY.FILTER, JSON.stringify(dataFilter?.filter))
            sessionStorage.setItem(SESSION_KEY.PAGINATION, JSON.stringify(dataFilter?.pagination))

            history.push("/transaksi/penjualan-langsung/tambah")
          }} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => dismissAlert()}
      />

      {/* Content */}
      {isPageLoading ? <DataStatus loading={true} text="Memuat data . . ." /> : <PageContent />}

      {/* Modal Filter */}
      {modalConfig.show && modalConfig.type === "filter" && (
        <ModalFilter
          SESSION_KEY={SESSION_KEY}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  )
}

export default ListPenjualanLangsung
