import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { IoEyeOutline } from "react-icons/io5";
import _ from "lodash";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  UpdateButton,
  ReadButton,
  InputSearch,
  Pagination,
  Table,
  Th,
  Td,
  ThFixed,
  TdFixed,
  CreateButton,
} from "components";
import { DateConvert, TableNumber, RupiahConvert } from "utilities";
import { FakturPenjualanApi } from "api";

const ListFakturPenjualan = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataFakturPenjualan, setDataFakturPenjualan] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    FakturPenjualanApi.get({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        setDataFakturPenjualan(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setPaginationConfig({
      page: "1",
      dataLength: "10",
      totalPage: "1",
      dataCount: "0",
    });
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    });
  };

  const generateStatusApproval = (status) => {
    const newStatus = status?.toUpperCase();
    if (newStatus === "REJ") {
      return "REJECTED";
    }

    if (newStatus === "REV") {
      return "REVISI";
    }

    if (newStatus === "APP") {
      return "APPROVED";
    }

    if (newStatus === "VER") {
      return "VERIFIED";
    }

    return "PENDING";
  };

  const sumTotal = (data) => {
    const dataList = data?.detail_faktur ?? [];
    const ppn = parseFloat(data?.ppn ?? 0);
    const diskon = parseFloat(data?.diskon ?? 0);

    const total = dataList.reduce((prev, current) => {
      const sum = parseFloat(current?.harga_satuan_jual ?? 0) * parseFloat(current?.qty ?? 0);

      return parseInt(prev + sum);
    }, 0);

    const totalAfterDiskon = total - (total / 100) * diskon;
    const totalAfterPPN = total - (totalAfterDiskon / 100) * ppn;

    return totalAfterPPN;
  };

  useEffect(() => {
    setNavbarTitle("Faktur Penjualan");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [setNavbarTitle, searchConfig.key, paginationConfig.page, paginationConfig.dataLength]);

  const PageContent = () => {
    const DataTable = () => (
      <Table hover={false}>
        <thead>
          <tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Tgl. Faktur Penjualan</ThFixed>
            <Th>No. Faktur Penjualan</Th>
            <Th>No. Penerimaan Surat Jalan</Th>
            <Th style={{ minWidth: 200 }}>Item Barang</Th>
            <Th>No. Sales Order</Th>
            <Th style={{ minWidth: 200 }}>Customer</Th>
            <Th style={{ minWidth: 150 }}>Total</Th>
            <Th>Status Approval</Th>
          </tr>
        </thead>
        <tbody>
          {dataFakturPenjualan.map((val, index) => {
            const rowSpan = val?.detail_faktur?.length ?? 1;

            return (
              <>
                <tr key={index}>
                  <TdFixed rowSpan={rowSpan}>
                    {TableNumber(paginationConfig.page, paginationConfig.dataLength, index)}
                  </TdFixed>
                  <Td rowSpan={rowSpan}>
                    <div className="d-flex">
                      <ReadButton
                        onClick={() =>
                          history.push(
                            "/transaksi/faktur-penjualan/detail/" + val.id_faktur_penjualan
                          )
                        }
                      />
                      {(val.status_approval === "REV" || !val.status_approval) && (
                        <UpdateButton
                          onClick={() =>
                            history.push(
                              "/transaksi/faktur-penjualan/ubah/" + val.id_faktur_penjualan
                            )
                          }
                        />
                      )}
                    </div>
                  </Td>
                  <Td rowSpan={rowSpan}>
                    {val.tgl_faktur ? DateConvert(new Date(val.tgl_faktur)).defaultDMY : "-"}
                  </Td>
                  <Td rowSpan={rowSpan}>{val.no_faktur ?? "-"}</Td>
                  <Td>{val?.detail_faktur[0]?.no_surat_jalan ?? "- "}</Td>
                  <Td>{val?.detail_faktur[0]?.nama_item ?? "- "}</Td>
                  <Td rowSpan={rowSpan}>{val.no_sales_order ?? "-"}</Td>
                  <Td rowSpan={rowSpan}>{val.nama_customer ?? "-"}</Td>
                  <Td rowSpan={rowSpan} textRight>
                    {RupiahConvert(sumTotal(val).toString()).detail}
                  </Td>
                  <Td rowSpan={rowSpan}>
                    {val.status_approval ? generateStatusApproval(val.status_approval) : "-"}
                  </Td>
                </tr>
                {rowSpan > 1 &&
                  val?.detail_faktur?.map(
                    (detail, i) =>
                      i !== 0 && (
                        <tr>
                          <Td>{detail?.no_surat_jalan ?? "-"}</Td>
                          <Td>{detail?.nama_item ?? "-"}</Td>
                        </tr>
                      )
                  )}
              </>
            );
          })}
        </tbody>
      </Table>
    );

    if (!dataFakturPenjualan || dataFakturPenjualan.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <div className="p-2">
          <b>List Data Faktur Penjualan</b>
        </div>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength - paginationConfig.dataLength + 1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch value={searchConfig.key} onChange={onInputSearchChange} />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() => history.push("/transaksi/faktur-penjualan/sales-order")}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Content */}
      {isPageLoading ? <DataStatus loading={true} text="Memuat data . . ." /> : <PageContent />}
    </CRUDLayout>
  );
};

export default ListFakturPenjualan;
