import React, { useState, useEffect, useRef } from "react"
import {
  useHistory,
  useParams
} from "react-router-dom"
import {
  Card,
  Row,
  Col
} from "react-bootstrap"
import {
  useReactToPrint
} from "react-to-print"
import {
  CRUDLayout,
  DataStatus,
  Alert,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  BackButton,
  ActionButton,
} from "components"
import { TransaksiSalesOrderApi } from "api"
import {
  RupiahConvert,
  DateConvert
} from "utilities"
import PrintSalesOrder from "./PrintSalesOrder"

const DetailSalesOrder = ({ setNavbarTitle }) => {
  const history = useHistory()
  const refPrint = useRef()
  const { id_sales_order } = useParams()

  const [isLoading, setIsLoading] = useState(true)
  const [dataSalesOrder, setDataSalesOrder] = useState({})
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    show: false,
    text: "",
  })

  const reactToPrint = useReactToPrint({ content: () => refPrint.current })
  const handlePrint = () => reactToPrint()

  const getData = () => {
    setIsLoading(true)

    TransaksiSalesOrderApi.getSingle(id_sales_order)
      .then(async (res) => setDataSalesOrder(res.data.data))
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    setNavbarTitle("Detail Sales Order")
    getData()
  }, [])

  // Komponen Detail Item vertical
  const InfoItemVertical = ({ title, value }) => (
    <div className='mb-2'>
      <small style={{ fontSize: 12 }} className="text-secondary">{title}</small>
      <div style={{ fontSize: 14 }}>{value}</div>
    </div>
  )

  // Komponen Detail Info Section
  const InfoSection = () => {
    return (
      <div className="row">
        <div className="col-md-3 col-sm-12">
          <InfoItemVertical
            title="Tanggal Sales Order"
            value={DateConvert(new Date(dataSalesOrder.tgl_sales_order)).detail} />

          <InfoItemVertical
            title="No. Sales Order"
            value={dataSalesOrder.no_sales_order} />

          <InfoItemVertical
            title="Batas Waktu Pengiriman"
            value={DateConvert(new Date(dataSalesOrder.batas_waktu)).detail} />
        </div>

        <div className="col-md-4 col-sm-12">
          <InfoItemVertical
            title="Sales"
            value={dataSalesOrder.nama_sales} />

          <InfoItemVertical
            title="Customer"
            value={dataSalesOrder.nama_customer} />
        </div>

        <div className="col-md-5 col-sm-12">
          <InfoItemVertical
            title="Alamat Pengiriman"
            value={dataSalesOrder.alamat_pengiriman} />
        </div>
      </div>
    )
  }

  // Komponen Detail Table Sales Order
  const Table = () => {
    // GET TOTAL ORDER
    const getTotal = () =>
      dataSalesOrder.detail?.reduce((sum, { sub_total }) => sum + parseInt(sub_total), 0)
    // GET TOTAL DISKON
    const getJumlahDiskon = () => {
      let total = getTotal()
      return String((total * dataSalesOrder.diskon) / 100)
    }
    // GET TOTAL SETELAH DISKON
    const getTotalSetelahDiskon = () => {
      let total = getTotal()
      let jmlDiskon = getJumlahDiskon()
      return String(total - jmlDiskon)
    }
    // GET TOTAL PPN
    const getJumlahPpn = () => {
      let totalSetelahDiskon = getTotalSetelahDiskon()
      return String((totalSetelahDiskon * dataSalesOrder.ppn) / 100)
    }
    // GET TOTAL SETELAH PPN
    const getTotalSetelahPpn = () => {
      let totalSetelahDiskon = parseInt(getTotalSetelahDiskon())
      let jumlahPpn = parseInt(getJumlahPpn())
      return String(totalSetelahDiskon + jumlahPpn)
    }

    return (
      <React.Fragment>
        <small>List Barang</small>
        <CRUDLayout.Table>
          <THead>
            <tr className='bg-light'>
              <ThFixed>No</ThFixed>
              <ThFixed>Kode Barang</ThFixed>
              <Th>Item Barang</Th>
              <Th>Gudang</Th>
              <Th style={{ width: 100 }}>Qty. SO</Th>
              <Th style={{ width: 100 }}>Satuan Jual</Th>
              <Th style={{ width: 150 }}>Harga Satuan Jual</Th>
              <Th style={{ width: 150 }}>Sub Total</Th>
            </tr>
          </THead>
          <TBody>
            {dataSalesOrder.detail?.map((order, index) => {
              order.sub_total = order.qty_item * order.harga_satuan_jual

              return (
                <Tr key={index}>
                  <TdFixed>{index + 1}</TdFixed>
                  <TdFixed>{order.kode_barang}</TdFixed>
                  <Td>{order.nama_barang}</Td>
                  <Td>{order.nama_gudang}</Td>
                  <Td textRight>{parseInt(order.qty_item)}</Td>
                  <Td>{order.nama_satuan}</Td>
                  <Td textRight>{order.harga_satuan_jual ? RupiahConvert(order.harga_satuan_jual).detail : "Rp. 0"}</Td>
                  <Td textRight>{order.sub_total ? RupiahConvert(String(parseInt(order.sub_total))).detail : "Rp. 0"}</Td>
                </Tr>
              )
            })}

            {dataSalesOrder?.detail?.length > 0 && (
              <>
                <Tr>
                  <Td colSpan={7} textRight>
                    <strong>Total</strong>
                  </Td>

                  <Td textRight>
                    <strong className="text-nowrap">
                      {RupiahConvert(String(parseInt(getTotal()))).detail}
                    </strong>
                  </Td>
                </Tr>
                <Tr>
                  <Td colSpan={7} textRight>
                    <strong>Diskon {`(${parseInt(dataSalesOrder.diskon)}%)`}</strong>
                  </Td>
                  <Td textRight>
                    <strong className="text-nowrap">
                      {RupiahConvert(parseInt(getJumlahDiskon()).toString()).detail}
                    </strong>
                  </Td>
                </Tr>
                <Tr>
                  <Td colSpan={7} textRight>
                    <strong className="text-nowrap">Total Setelah Diskon</strong>
                  </Td>

                  <Td textRight>
                    <strong className="text-nowrap">
                      {RupiahConvert(parseInt(getTotalSetelahDiskon()).toString()).detail}
                    </strong>
                  </Td>
                </Tr>
                <Tr>
                  <Td colSpan={7} textRight>
                    <strong>PPN {`(${parseInt(dataSalesOrder.ppn)}%)`}</strong>
                  </Td>
                  <Td textRight>
                    <strong className="text-nowrap">
                      {RupiahConvert(parseInt(getJumlahPpn()).toString()).detail}
                    </strong>
                  </Td>
                </Tr>
                <Tr>
                  <Td colSpan={7} textRight>
                    <strong>Total Setelah PPN</strong>
                  </Td>

                  <Td textRight>
                    <strong className="text-nowrap">
                      {RupiahConvert(parseInt(getTotalSetelahPpn()).toString()).detail}
                    </strong>
                  </Td>
                </Tr>
              </>
            )}
          </TBody>
        </CRUDLayout.Table>
      </React.Fragment>
    )
  }

  // Komponen Catatan Approval Sales Order
  const FormCard = () => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    )

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Catatan Approval Sales Order</b>
        </Card.Header>
        <Card.Body>
          {dataSalesOrder?.stakeholder?.length > 1 ?
            dataSalesOrder?.stakeholder?.map(
              (val, index) => Boolean(index !== 0 && val.status_approval !== "PEN") && (
                <Row>
                  <Col sm>
                    <InfoItem
                      title1={
                        val.status_approval === "VER" || val.status_approval === "REV"
                          ? `Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}`
                          : val.status_approval === "APP"
                            ? "Pengesah"
                            : "Di Tolak Oleh"
                      }
                      value1={val.nama_karyawan ?? "-"}
                      title2="Catatan"
                      value2={val.catatan ?? "-"}
                    />
                    {console.log(val.status_approval)}
                  </Col>
                </Row>
              ))
            : (
              <div className="d-flex justify-content-center text-center">
                <DataStatus text="Tidak ada catatan" />
              </div>
            )}
        </Card.Body>
      </Card>
    )
  }

  return (
    <>
      <CRUDLayout>
        <Alert
          show={alertConfig.show}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setAlertConfig((prev) => ({ ...prev, show: false }))}
        />

        {isLoading
          ? <DataStatus loading={isLoading} text="Memuat Data" />
          : <Card className="pb-3 mt-3">
            <Card.Header className="d-flex justify-content-between align-items-center font-weight-bold">
              Detail Sales Order

              <div className="d-flex justify-content-end align-items-end">
                <ActionButton
                  className="btn btn-primary text-white mx-1 mb-1"
                  size="md"
                  text="Cetak"
                  onClick={handlePrint} />

                <BackButton size="md" onClick={() => history.goBack()} />
              </div>
            </Card.Header>

            <Card.Body>
              <InfoSection />

              <hr className="mb-3" />

              {Boolean(dataSalesOrder?.detail && dataSalesOrder?.detail?.length > 0)
                ? <Table />
                : <DataStatus text="Daftar item barang kosong" />
              }

              <InfoItemVertical
                title="Catatan Sales Order"
                value={dataSalesOrder.catatan} />

              <div style={{ display: "none" }}>
                <PrintSalesOrder
                  id={id_sales_order}
                  ref={refPrint}
                  dataPrint={dataSalesOrder}
                  table={Table} />
              </div>
            </Card.Body>
          </Card>
        }
      </CRUDLayout>
      {!isLoading && <FormCard />}
    </>
  )
}

export default DetailSalesOrder
