// PAGE RETUR PENJUALAN

import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
    CRUDLayout,
    InputSearch,
    ExportButton,
    CreateButton,
    ActionButton,
    PrintButton,
    DataStatus,
    UpdateButton,
    DeleteButton,
    Alert,
    Pagination,
    THead,
    TBody,
    ThFixed,
    TdFixed,
    Tr,
    Th,
    DeleteModal,
    Td,
    ReadButton,
} from '../../../components'
import Axios from 'axios'
import { useReactToPrint } from 'react-to-print'
import { ReturPenjualanApi } from '../../../api'
import { DateConvert, PageNumber } from '../../../utilities'


// PRINT LAYOUT COMPONENT
const ReturPenjualan = ({ setNavbarTitle }) => {
    const title = "Retur Penjualan";
    // USE HISTORY 
    const history = useHistory();

    const location = useLocation();
    let registerAlertConfig = location?.state?.registerAlertConfig

    // STATE LOADING 
    const [isLoading, setIsLoading] = useState(true);

    // STATE SEARCHING
    const [isSearching, setIsSearching] = useState(false);
    const [searchKey, setSearchKey] = useState('');

    // MODALS SHOW STATE
    const [isUpdateForm, setIsUpdateForm] = useState(false); // MODAL UPDATE STATE
    const [isDeleteData, setIsDeleteData] = useState(false); // MODAL HAPUS STATE
    const [isViewData, setIsViewData] = useState(false); //MODAL VIEW STATE
    const [isStatusModal, setIsStatusModal] = useState({ show: false, title: " " }); // STATUS MODAL STATE

    // STATE DATA SATUAN
    const [data, setData] = useState([]);
    const [dataCount, setDataCount] = useState(0)
    const [page, setPage] = useState(1);
    const [dataLength, setDataLength] = useState(10);
    const [totalPage, setTotalPage] = useState(1);

    // STATE MENAMPUNG DATA YANG AKAN DI HAPUS
    const [deleteData, setDeleteData] = useState("");
    // ALERT STATE
    const [showAlert, setShowAlert] = useState(false);
    // KONFIGURASI ALERT
    const [alertConfig, setAlertConfig] = useState({
        variant: "primary",
        text: "",
    });

    // FUNCTION GET DATA DARI SERVER
    const getData = () => {
        setIsLoading(true);
        setIsSearching(false);
        setSearchKey('');

        Axios.all([
            ReturPenjualanApi.getPage(page, dataLength),
        ]).then(
            Axios.spread((res) => {
                sortedByDateList(res.data.data);
                setTotalPage(res.data.total_page);
                setDataCount(res.data.data_count);
            })
        )
            .catch((err) => alert(err))
            .finally(() => setIsLoading(false))
    }

    // FUNCTION KONVERSI TANGGAL INPUT
    const getConvertedDate = (inputDate) => {
        if (inputDate != null) {
            const getDate = inputDate.split(" ");
            const date = new Date(getDate[0]);
            return `${DateConvert(date).defaultDay}/${DateConvert(date).defaultMonth}/${DateConvert(date).defaultYear}`
            // return DateConvert(date).default
        }
        return "-"
    }

    // FUNCTION SORTIR LIST ITEM BAHAN BERDASARKAN TANGGAL TERBARU
    const sortedByDateList = (listItemBahan) => {
        const sorted = listItemBahan.sort(function (a, b) {
            return new Date(b.tgl_input) - new Date(a.tgl_input);
        });
        setData(sorted);
    }

    // FUNCTION CARI DATA DARI SERVER 
    const searchData = (e) => {
        e.preventDefault();
        const key = e.target.value  // SearchKey Value as key

        setSearchKey(key)
        setIsLoading(true);

        // SEARCH DATA WITH KEY FROM DATABASE
        ReturPenjualanApi.search(key)
            .then((res) => {
                sortedByDateList(res.data.data);
                setTotalPage(res.data.total_page);
                setDataCount(res.data.data_count)
            })
            .catch((err) => alert(err))
            .finally(() => {
                setIsSearching(true);
                setIsLoading(false);

                key !== '' && setAlertConfig({
                    variant: "primary",
                    text: `Hasil Pencarian : ${key}`,
                });
                key !== '' ? setShowAlert(true) : setShowAlert(false);
            });
    }

    // ON COMPONENT MOUNT
    useEffect(() => {
        setNavbarTitle(title); // SET JUDUL NAVBAR
        getData();  // GET DATA DARI SERVER

        // SHOW ALERT, IF REGISTER ALERT IS EXIST
        if (registerAlertConfig !== undefined) {
            setAlertConfig(registerAlertConfig)
            setShowAlert(true);
        }

        return () => {
            setIsLoading(false);
            setIsSearching(false);
        }
    }, [page, dataLength])

    // MODAL HAPUS COMPONENT
    const HapusModal = () => {
        // MENANGANI DELETE BUTTON LOADING
        const [btnLoading, setBtnLoading] = useState(false);

        // DELETE DATA DARI SERVER
        const deleteDataHandler = () => {
            setBtnLoading(true);

            ReturPenjualanApi.deleteReturPenjualan(deleteData.id_retur_penjualan).then(() => {
                // KONFIGURASI ALERT
                setAlertConfig({
                    variant: "primary",
                    text: "Hapus data berhasil!",
                });
            }).catch((err) => {
                // KONFIGURASI ALERT
                setAlertConfig({
                    variant: "danger",
                    text: `Hapus data gagal! (${err.response.data.message})`,
                });
            }).finally(() => {
                // CLOSE MODAL
                setIsDeleteData(false);
                // TAMPIL ALERT
                setShowAlert(true);
                // FETCH DATA DARI SERVER
                getData();
            })
        }

        return (
            <DeleteModal
                show={isDeleteData}
                onHide={() => setIsDeleteData(false)}
                loading={btnLoading}
                onConfirm={deleteDataHandler}
                title={title} >
                <div>No. Retur Penjualan : {deleteData.no_retur_penjualan}</div>
            </DeleteModal>
        )
    }

    // MAIN TABLE COMPONENT
    const Table = () => {
        return (
            <>
                <CRUDLayout.Table>
                    <THead>
                        <Tr className="text-center">
                            <ThFixed>No</ThFixed>
                            <ThFixed>Aksi</ThFixed>
                            <ThFixed>Tgl. Retur Penjualan</ThFixed>
                            <ThFixed>No. Retur Penjualan</ThFixed>
                            <ThFixed>No. Faktur Penjualan</ThFixed>
                            <ThFixed>No. Sales Order</ThFixed>
                            <Th>Nama Customer</Th>
                            <Th>Nama Sales</Th>
                        </Tr>
                    </THead>
                    <TBody>
                        {data.map((val, index) => {
                            return (
                                <Tr key={val.id_retur_penjualan}>
                                    <TdFixed >{PageNumber(page, dataLength, index)}</TdFixed>
                                    <TdFixed >
                                        <div className="d-flex flex-row justify-content-center">
                                            <ReadButton
                                                onClick={() => {
                                                    console.log("id_retur_penjualan: ", val.id_retur_penjualan)
                                                    history.push(`/transaksi/retur-penjualan/detail/${val.id_retur_penjualan}`,
                                                        {
                                                            tgl_retur_penjualan: val.tgl_retur_penjualan,
                                                            no_retur_penjualan: val.no_retur_penjualan
                                                        })
                                                }} />
                                            {/* //TODO: BISA DI UPDATE APABILA BELUM MEMPUNYAI PENERIMAAN  RETUR  DI INV*/}
                                            <UpdateButton onClick={() => {
                                                history.push(`/transaksi/retur-penjualan/update/${val.id_retur_penjualan}`, {
                                                    // id_faktur_penjualan: val?.id_faktur_penjualan,
                                                    tgl_retur_penjualan: val.tgl_retur_penjualan,
                                                    no_retur_penjualan: val.no_retur_penjualan
                                                })
                                            }} />
                                            {/* //TODO:  BISA DI DELETE APABILA BELUM MEMPUNYAI PENERIMAAN  RETUR  DI INV*/}
                                            <DeleteButton onClick={() => {
                                                setDeleteData(val)
                                                setIsDeleteData(true)
                                            }} />
                                            {/* <PrintButton
                                                onClick={() => {
                                                    handlePrint(val.id_retur_penjualan)
                                                }} /> */}
                                        </div>
                                    </TdFixed>
                                    <TdFixed>{getConvertedDate(val.tgl_retur_penjualan)}</TdFixed >
                                    <TdFixed>{val.no_retur_penjualan}</TdFixed>
                                    <TdFixed>{val.no_faktur}</TdFixed>
                                    <TdFixed>{val.no_sales_order}</TdFixed>
                                    <Td>{val.nama_customer}</Td>
                                    <Td>{val.nama_karyawan}</Td>
                                </Tr>
                            );
                        })}
                    </TBody>
                </CRUDLayout.Table>
                <Pagination
                    dataLength={dataLength}
                    dataNumber={page * dataLength - dataLength + 1}
                    dataPage={page * dataLength}
                    dataCount={dataCount}
                    onDataLengthChange={(e) => {
                        setPage(1)
                        setDataLength(e.target.value)
                    }}
                    currentPage={page}
                    totalPage={totalPage}
                    onPaginationChange={({ selected }) => setPage(selected + 1)}
                />
            </>
        )
    }

    return (
        <CRUDLayout>
            <CRUDLayout.Head>
                <CRUDLayout.HeadSearchSection>
                    <div className="d-flex mb-3">
                        <InputSearch placeholder="Cari Retur Penjualan"
                            value={searchKey}
                            onChange={searchData}
                        />
                    </div>
                </CRUDLayout.HeadSearchSection>

                {/* Button Section */}
                <CRUDLayout.HeadButtonSection>
                    {/* Button Export */}
                    {/* <ExportButton /> */}

                    {/* Button Tambah */}
                    <CreateButton onClick={() => {
                        history.push('/transaksi/retur-penjualan/list-faktur-penjualan/retur')
                        // setIsCreateForm(true)
                    }} />
                </CRUDLayout.HeadButtonSection>
            </CRUDLayout.Head>

            {/* Alert */}
            <Alert show={showAlert}
                showCloseButton={true}
                variant={alertConfig.variant}
                text={alertConfig.text}
                onClose={() => {
                    setShowAlert(false)
                    // CLEAR HISTORY LOCATION
                    history.replace('/transaksi/retur-penjualan', { registerAlertConfig: undefined })
                }} />

            {
                isLoading ? (<DataStatus loading={isLoading} text="Memuat Data" />) :
                    (!(Array.isArray(data))) ? (<DataStatus text="Tidak dapat memuat data" />)
                        : data.length > 0
                            ? (<Table />)
                            : (<DataStatus text="Tidak ada data" />)
            }

            {/* MODAL */}
            {isDeleteData && <HapusModal />}
        </CRUDLayout >
    )
}

export default ReturPenjualan



