import Services from "services"

class SalesOrderSPKApi {
  get(params) {
    return Services.get("/sales_order_spk/page", { params })
  }

  getSingleSOSPK(params) {
    return Services.get("/sales_order_spk/single", { params })
  }

  getListSPK(params) {
    return Services.get("/sales_order_spk/list_spk", { params })
  }

  getSingleSPK(params) {
    return Services.get("/sales_order_spk/single_spk", { params })
  }

  getGudang() {
    return Services.get("/sales_order_spk/dropdown_gudang")
  }

  getNomor(params) {
    return Services.get("/sales_order_spk/no_baru", { params })
  }

  getKaryawan(params) {
    return Services.get("/karyawan", { params })
  }

  create(data) {
    return Services.post("/sales_order_spk", data)
  }

  update(data) {
    return Services.put("/sales_order_spk", data)
  }
}

export default new SalesOrderSPKApi();
