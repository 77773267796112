import React, {
  useState,
  useEffect
} from "react";
import {
  useHistory,
  useLocation
} from "react-router-dom";
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  DataStatus,
  DeleteModal,
  UpdateButton,
  DeleteButton,
  Alert,
  Pagination,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  ReadButton
} from "components";
import {
  DateConvert,
  PageNumber,
  RupiahConvert
} from "utilities";
import { TransaksiSalesOrderApi } from "api";

const TransaksiSalesOrder = ({ setNavbarTitle }) => {
  const title = "Sales Order";
  const history = useHistory();
  const location = useLocation();
  let registerAlertConfig = location?.state?.registerAlertConfig;

  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteData, setIsDeleteData] = useState(false);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [deleteData, setDeleteData] = useState("");
  const [dataCount, setDataCount] = useState(0);
  const [dataLength, setDataLength] = useState(10);
  const [totalPage, setTotalPage] = useState(1);
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    show: false,
    text: "",
  });

  // FUNCTION SORTIR LIST ITEM BAHAN BERDASARKAN TANGGAL TERBARU
  const sortedByDateList = (listItemBahan) => {
    const sorted = listItemBahan.sort(function (a, b) {
      return new Date(b.tgl_input) - new Date(a.tgl_input);
    });
    setData(sorted);
  };

  const getData = () => {
    setIsLoading(true);
    setSearchKey("");

    TransaksiSalesOrderApi.getPage(page, dataLength)
      .then((res) => {
        sortedByDateList(res.data.data);
        setTotalPage(res.data.total_page);
        setDataCount(res.data.data_count);
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  };

  const searchData = (e) => {
    e.preventDefault();
    const key = e.target.value;

    setSearchKey(key);
    setIsLoading(true);

    // SEARCH DATA WITH KEY FROM DATABASE
    TransaksiSalesOrderApi.search(key)
      .then((res) => {
        // setData(res.data.data);
        sortedByDateList(res.data.data);
        setTotalPage(res.data.total_page);
        setDataCount(res.data.data_count);
      })
      .catch((err) => alert(err))
      .finally(() => {
        setIsLoading(false);

        Boolean(key) && setAlertConfig({
          variant: "primary",
          show: true,
          text: `Hasil Pencarian : ${key}`,
        });
      });
  };

  const dismissAlert = () => {
    history.replace("/transaksi/sales-order", { registerAlertConfig: undefined });
    setAlertConfig((prev) => ({ ...prev, show: false }))
  }

  useEffect(() => {
    setNavbarTitle(title);
    getData();

    if (registerAlertConfig !== undefined) setAlertConfig({ ...registerAlertConfig, show: true });
    return () => setIsLoading(false)

  }, [page, dataLength]);

  const HapusModal = () => {
    const [btnLoading, setBtnLoading] = useState(false);

    const deleteDataHandler = () => {
      setBtnLoading(true);

      TransaksiSalesOrderApi.deleteSalesOrder(deleteData.id_sales_order)
        .then(() => setAlertConfig({
          variant: "primary",
          show: true,
          text: "Hapus data berhasil!",
        }))
        .catch((err) => setAlertConfig({
          variant: "danger",
          show: true,
          text: `Hapus data gagal! (${err.response.data.message})`,
        }))
        .finally(() => {
          setIsDeleteData(false);
          getData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <div>Nomor Sales Order : {deleteData.no_sales_order}</div>
      </DeleteModal>
    );
  };

  const Table = () => {
    const statusApprovalLabel = {
      APP: 'APPROVED',
      REJ: 'REJECTED',
      VER: 'VERIFIED',
      REV: 'REVISE'
    }

    const hitungItemTotal = (data) => {
      const { barang_jadi, diskon, ppn } = data

      const totalBarangJadi = barang_jadi?.reduce((total, { harga_satuan_jual, qty_item }) => total + (parseInt(harga_satuan_jual ?? 0) * parseFloat(qty_item ?? 0)), 0)
      const jmlDiskon = (totalBarangJadi * diskon ?? 0) / 100;
      const totalSetelahDiskon = totalBarangJadi - jmlDiskon;
      const jmlPpn = (totalSetelahDiskon * ppn ?? 0) / 100;
      const totalSetelahPpn = totalSetelahDiskon + jmlPpn;

      return parseInt(totalSetelahPpn)
    }

    const ItemCollapse = ({ data }) => {
      const [isReadMore, setIsReadMore] = useState(false);

      return (
        <div>
          <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
            {data.map((brg, index) => {
              if (isReadMore) {
                return <li key={index} index={index}>{brg.nama_barang}</li>
              }
              return index < 2 && <li key={index} index={index}>{brg.nama_barang}</li>;
            })}
          </ul>

          {data.length > 2 && (
            <div
              className="text-primary"
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => setIsReadMore((prev) => !prev)}>
              {isReadMore ? "Sembunyikan" : "Selengkapnya..."}
            </div>
          )}
        </div>
      )
    }

    return (
      <>
        <small className="font-weight-bold">List Data Sales Order</small>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Tgl. Sales Order</ThFixed>
              <ThFixed>No. Sales Order</ThFixed>
              <Th>Customer</Th>
              <Th>Sales</Th>
              <Th>List Barang</Th>
              <Th>Total</Th>
              <ThFixed>Batas Waktu Pengiriman </ThFixed>
              {/* <ThFixed>Status Approval</ThFixed> */}
            </Tr>
          </THead>
          <TBody>
            {data?.map((val, index) => {
              return (
                <Tr key={val.id_sales_order}>
                  <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
                  <TdFixed>
                    <div className="d-flex flex-row justify-content-start">
                      <ReadButton
                        onClick={() => {
                          history.push(`/transaksi/sales-order/detail/${val.id_sales_order}`);
                        }}
                      />

                      {val.status_approval === 'REV' && (
                        <UpdateButton
                          onClick={() => {
                            history.push(`/transaksi/sales-order/update/${val.id_sales_order}`);
                          }}
                        />
                      )}

                      {val.mod_status === "EDITABLE" && (
                        <DeleteButton
                          onClick={() => {
                            setDeleteData(val);
                            setIsDeleteData(true);
                          }}
                        />
                      )}
                    </div>
                  </TdFixed>
                  <TdFixed>{DateConvert(new Date(val.tgl_sales_order)).defaultDMY}</TdFixed>
                  <TdFixed>{val.no_sales_order}</TdFixed>
                  <Td>{val.nama_customer}</Td>
                  <Td>{val.nama_sales}</Td>
                  <Td>
                    {val.barang_jadi && val.barang_jadi?.length > 0
                      ? <ItemCollapse data={val.barang_jadi} />
                      : "-"
                    }
                  </Td>
                  <Td className="text-right">{RupiahConvert(String(hitungItemTotal(val))).detail}</Td>
                  <TdFixed>{DateConvert(new Date(val.batas_waktu)).defaultDMY}</TdFixed>
                  {/* <TdFixed>{statusApprovalLabel[val.status_approval] || 'PENDING'}</TdFixed> */}
                </Tr>
              )
            })}
          </TBody>
        </CRUDLayout.Table>

        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataPage={page * dataLength}
          dataCount={dataCount}
          onDataLengthChange={(e) => {
            setPage(1);
            setDataLength(e.target.value);
          }}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        {/* Search */}
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch placeholder="Cari Sales Order" value={searchKey} onChange={searchData} />
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* Button Section */}
        <CRUDLayout.HeadButtonSection>
          <CreateButton onClick={() => history.push("/transaksi/sales-order/tambah")} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => dismissAlert()}
      />

      {isLoading
        ? <DataStatus loading={isLoading} text="Memuat Data" />
        : (data?.length > 0)
          ? <Table />
          : <DataStatus text="Tidak ada data" />
      }

      {isDeleteData && <HapusModal />}
    </CRUDLayout>
  );
};

export default TransaksiSalesOrder;
