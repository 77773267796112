import {
  IoCheckboxOutline
} from 'react-icons/io5'
import {
  Table,
  Th,
  Td,
  ThFixed,
  DataStatus,
  TdFixed
} from 'components'
import {
  RupiahConvert,
  DateConvert
} from 'utilities'

const TableSection = ({ formik, dataTable, setDataTable }) => {
  const { values, setFieldValue } = formik

  const decimalConvert = value => {
    const newValue = value.toString().replace(/[^0-9\.]/g, "")
    const convert = newValue.substring(0, 3)

    if (parseInt(convert) > 100) {
      return 100
    }

    return convert
  }

  const onCheckedHandler = index => {
    const newData = dataTable.map((val, i) => {
      return i === index ? { ...val, checked: !val.checked } : { ...val }
    })

    setDataTable(newData)
  }

  const getTotalFooter = () => {
    const getTotal = () => dataTable.reduce((prev, current) => {
      const total = parseInt(parseFloat(current.qty) * parseInt(current.harga))

      return prev + total
    }, 0)

    const getDiskon = () => {
      const total = getTotal()
      const diskon = values.diskon
      const totalDiskon = (total / 100) * diskon

      return totalDiskon
    }

    const getTotalAfterDiskon = () => {
      const total = getTotal()
      const totalDiskon = getDiskon()

      return total - totalDiskon
    }

    const getPPN = () => {
      const totalAfterDiskon = getTotalAfterDiskon()
      const ppn = values.ppn
      const totalPPN = (totalAfterDiskon / 100) * ppn

      return totalPPN
    }

    const getTotalAfterPPN = () => {
      const ppn = getPPN()
      const totalAfterDiskon = getTotalAfterDiskon()

      return totalAfterDiskon + ppn
    }

    return {
      total: RupiahConvert(String(parseInt(getTotal()))).detail,
      diskon: RupiahConvert(String(parseInt(getDiskon()))).detail,
      ppn: RupiahConvert(String(parseInt(getPPN()))).detail,
      totalAfterDiskon: RupiahConvert(String(parseInt(getTotalAfterDiskon()))).detail,
      totalAfterPPN: RupiahConvert(String(parseInt(getTotalAfterPPN()))).detail,
    }
  }

  const Checkbox = ({ checked, onClick }) => (
    <div className="form-check" onClick={onClick}>
      <input
        type="checkbox"
        className="form-check-input mt-0"
        style={{ width: 20, height: 20 }}
        defaultChecked={checked}
      />
    </div>
  )

  return (
    <>
      <div className="p-1">
        <b>List Barang</b>
      </div>
      <Table>
        <thead className="bg-light">
          <Th>No</Th>
          <Th>Tgl. Penerimaan Surat Jalan</Th>
          <Th>No. Penerimaan Surat Jalan</Th>
          <Th>No. Surat Jalan</Th>
          <Th width={300}>Item Barang</Th>
          <Th>Qty</Th>
          <Th>Harga Satuan</Th>
          <Th>Jumlah</Th>
        </thead>
        <tbody>
          {dataTable.length > 0
            ? dataTable.map((val, index) => (
              <tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <Td>{val.tgl_penerimaan_surat_jalan ? DateConvert(new Date(val.tgl_penerimaan_surat_jalan)).defaultDMY : ''}</Td>
                <Td>{val.no_penerimaan_surat_jalan}</Td>
                <Td>{val.no_surat_jalan}</Td>
                <Td>{val.nama_item}</Td>
                <Td textRight className="text-nowrap">{val.qty} {val.satuan}</Td>
                <Td textRight>{RupiahConvert(val.harga.toString()).detail}</Td>
                <Td textRight>{RupiahConvert((val.qty * val.harga).toString()).detail}</Td>
              </tr>
            ))
            : <tr>
              <td colSpan={8}>
                <DataStatus text="Tidak ada data" />
              </td>
            </tr>
          }
        </tbody>
        <tfoot style={{ fontSize: 13 }}>
          {/* Total */}
          <tr className="bg-light">
            <td colSpan={7} className="p-2 text-right font-weight-bold align-middle">Total</td>
            <td className="py-2 px-1 text-right font-weight-bold align-middle">{getTotalFooter().total}</td>
          </tr>
          {/* Diskon */}
          <tr>
            <td colSpan={7} className="p-0 font-weight-bold">
              <div className="d-flex justify-content-end align-items-center">
                <div className="mx-2">Diskon (%)</div>
                <input
                  className="form-control form-control-sm"
                  style={{ width: 60 }}
                  value={values.diskon}
                  onChange={e => {
                    const value = e.target.value
                    const convert = decimalConvert(value)
                    setFieldValue("diskon", convert)
                  }}
                />
              </div>
            </td>
            <td className="py-0 px-1 text-right font-weight-bold align-middle">{getTotalFooter().diskon}</td>
          </tr>
          {/* Total Setelah Diskon */}
          <tr className="bg-light">
            <td colSpan={7} className="p-2 text-right font-weight-bold align-middle">Total Setelah Diskon</td>
            <td className="py-2 px-1 text-right font-weight-bold align-middle">{getTotalFooter().totalAfterDiskon}</td>
          </tr>
          {/* PPN */}
          <tr >
            <td colSpan={7} className="p-0 font-weight-bold">
              <div className="d-flex justify-content-end align-items-center">
                <div className="mx-2">PPN (%)</div>
                <input
                  className="form-control form-control-sm"
                  style={{ width: 60 }}
                  value={values.ppn}
                  onChange={e => {
                    const value = e.target.value
                    const convert = decimalConvert(value)
                    setFieldValue("ppn", convert)
                  }}
                />
              </div>
            </td>
            <td className="py-0 px-1 text-right font-weight-bold align-middle">{getTotalFooter().ppn}</td>
          </tr>
          {/* Total Setelah PPN */}
          <tr className="bg-light">
            <td colSpan={7} className="py-2 px-1 text-right font-weight-bold align-middle">Total Setelah PPN</td>
            <td className="py-2 px-1 text-right font-weight-bold align-middle">{getTotalFooter().totalAfterPPN}</td>
          </tr>
        </tfoot>
      </Table>
    </>
  )
}

export default TableSection