import React from 'react'
import {
  Table,
  TdFixed,
  ThFixed,
  Th,
  Td,
  DeleteButton,
  Input
} from "components"
import { RupiahConvert } from 'utilities'

const TableKeranjangBelanja = ({ formik, data, setData, type = "CREATE", dataInfo = {}, hideGudang = false }) => {
  const decimalConvert = value => {
    const newValue = value.toString().replace(/[^0-9\.]/g, "")
    const convert = newValue.substring(0, 5)
    if (parseInt(convert) > 100) return 100
    return convert
  }

  const handleDeleteItem = index => setData(prev => prev.filter((val, i) => i !== index))

  const diskon = type === "CREATE" ? parseFloat(formik.values.diskon ?? 0) : dataInfo?.diskon ?? 0
  const ppn = type === "CREATE" ? parseFloat(formik.values.ppn ?? 0) : dataInfo?.ppn ?? 0
  const cash = type === "CREATE" ? parseInt(Boolean(formik.values.cash ?? 0)) : dataInfo?.cash ?? 0

  const calcTable = () => {

    const floatDiskon = parseFloat(diskon || 0)
    const floatPPN = parseFloat(ppn || 0)

    const total = data?.reduce((acc, val) => Math.round(acc + parseFloat(val?.qty_belanja || 0) * parseFloat(val?.harga_terbaru || 0)), 0)
    const jumlahDiskon = ((total || 0) * floatDiskon) / 100
    const totalSetelahDiskon = total - jumlahDiskon
    const jumlahPPN = (totalSetelahDiskon * floatPPN) / 100
    const totalSetelahPPN = totalSetelahDiskon + jumlahPPN
    const totalSetelahCash = cash - total

    return { total, jumlahDiskon, totalSetelahDiskon, jumlahPPN, totalSetelahPPN, totalSetelahCash }
  }

  const { total, jumlahDiskon, totalSetelahDiskon, jumlahPPN, totalSetelahPPN, totalSetelahCash } = calcTable()

  return (
    <>
      <div className='font-weight-bold mt-3 mb-1'>Keranjang Belanja</div>
      <Table hover={false}>
        <thead>
          <tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Kode Barang</ThFixed>
            <Th>Item Barang</Th>
            {!hideGudang && <Th>Gudang</Th>}
            <ThFixed>Satuan Jual</ThFixed>
            <ThFixed>Qty.Belanja</ThFixed>
            <Th width={150}>Harga Satuan Jual</Th>
            <Th width={150}>Sub Total</Th>
            {type !== "DETAIL" && <ThFixed>Aksi</ThFixed>}
          </tr>
        </thead>
        <tbody>
          {data?.map((val, index) => {
            const sub_total = parseFloat(val?.qty_belanja ?? 0) * parseFloat(val.harga_terbaru ?? 0)
            return (
              <tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <Td>{val.kode_barang}</Td>
                <Td>{val.nama_barang}</Td>
                {!hideGudang && <Td>{val.nama_gudang}</Td>}
                <Td>{val.nama_satuan}</Td>
                <Td textRight>{parseFloat(val.qty_belanja ?? 0)}</Td>
                <Td textRight>{RupiahConvert(String(parseFloat(val.harga_terbaru ?? 0))).detail}</Td>
                <Td textRight>{RupiahConvert(String(Math.round(sub_total || 0))).detail}</Td>
                {type !== "DETAIL" && (<Td>
                  <DeleteButton onClick={() => handleDeleteItem(index)} />
                </Td>)}
              </tr>
            )
          })}

          {/* Total */}
          <tr>
            <Td colSpan={hideGudang ? 6 : 7} className="text-right"> <b> Total </b> </Td>
            <Td textRight className="font-weight-bold">{RupiahConvert(String(total)).detail}</Td>
            {type !== "DETAIL" && <Td />}
          </tr>

          {/* Diskon */}
          <tr>
            <Td colSpan={hideGudang ? 6 : 7} className="text-right">
              {type === "CREATE"
                ? (<div className="mt-1 d-flex justify-content-end align-items-center">
                  <b className="mr-2">Diskon (%)</b>
                  <div style={{ width: 80 }}>
                    <Input
                      className="text-right"
                      value={formik?.values?.diskon}
                      onChange={(e) => {
                        const value = e.target.value
                        const newValue = decimalConvert(value)

                        formik.setFieldValue('diskon', newValue)
                      }}
                    />
                  </div>
                </div>)
                : (<b className="mr-2">Diskon ({diskon}%)</b>)}
            </Td>
            <Td textRight className="font-weight-bold">{RupiahConvert(String(jumlahDiskon)).detail}</Td>
          </tr>

          {/* Total Setelah Dikurangi Diskon*/}
          <tr>
            <Td colSpan={hideGudang ? 6 : 7} className="text-right"> <b> Total Setelah Dikurangi Diskon</b> </Td>
            <Td textRight className="font-weight-bold">{RupiahConvert(String(totalSetelahDiskon)).detail}</Td>
            {type !== "DETAIL" && <Td />}
          </tr>

          {/* PPN */}
          <tr>
            <Td colSpan={hideGudang ? 6 : 7} className="text-right">
              {type === "CREATE"
                ? (
                  <div className="mt-1 d-flex justify-content-end align-items-center">
                    <b className="mr-2">PPN (%)</b>
                    <div style={{ width: 80 }}>
                      <Input
                        className="text-right"
                        value={formik?.values.ppn}
                        onChange={(e) => {
                          const value = e.target.value
                          const newValue = decimalConvert(value)

                          formik.setFieldValue('ppn', newValue)
                        }}
                      />
                    </div>
                  </div>
                )
                : (<b className="mr-2">PPN ({ppn}%)</b>)
              }
            </Td>
            <Td textRight className="font-weight-bold">{RupiahConvert(String(Math.round(jumlahPPN))).detail}</Td>
          </tr>

          {/* Total Setelah Ditambah PPN*/}
          <tr>
            <Td colSpan={hideGudang ? 6 : 7} className="text-right"> <b> Total Setelah Ditambah PPN </b> </Td>
            <Td textRight className="font-weight-bold">{RupiahConvert(String(Math.round(totalSetelahPPN))).detail}</Td>
            {type !== "DETAIL" && <Td />}
          </tr>

          {/* Grand Total */}
          <tr>
            <Td colSpan={hideGudang ? 6 : 7} className="text-right"> <b> Grand Total </b> </Td>
            <Td textRight className="font-weight-bold">{RupiahConvert(String(Math.round(totalSetelahPPN))).detail}</Td>
            {type !== "DETAIL" && <Td />}
          </tr>
        </tbody>

        {/* Cash */}
        <tr>
            <Td colSpan={hideGudang ? 6 : 7} className="text-right">
              {type === "CREATE"
                ? (
                  <div className="mt-1 d-flex justify-content-end align-items-center">
                    <b className="mr-2">Cash</b>
                    <div style={{ width: 120 }}>
                      <Input
                        className="text-right"
                        value={RupiahConvert(String(parseInt(formik?.values.cash || 0))).detail}
                        onChange={(e) => {
                          const value = RupiahConvert(e.target.value).default
                          formik.setFieldValue('cash', value)
                        }}
                      />
                    </div>
                  </div>
                )
                : (<b className="mr-2">Cash</b>)
              }
            </Td>
            {type === "CREATE"
              ? <Td textRight className="font-weight-bold">{RupiahConvert(String(Math.round(formik?.values?.cash || 0))).detail}</Td>
              : <Td textRight className="font-weight-bold">{RupiahConvert(String(Math.round(cash || 0))).detail}</Td>
            }
        </tr>

        {/* Total Setelah Cash */}
        <tr>
          <Td colSpan={hideGudang ? 6 : 7} className="text-right"> <b> Sisa </b> </Td>
          {type === "CREATE"
            ? <Td textRight className="font-weight-bold">{RupiahConvert(String(Math.round(formik?.values?.cash - totalSetelahPPN || 0))).detail}</Td>
            : <Td textRight className="font-weight-bold">{RupiahConvert(String(Math.round(cash - totalSetelahPPN || 0))).detail}</Td>
          }
          {type !== "DETAIL" && <Td />}
        </tr>
      </Table>
    </>
  )
}

export default TableKeranjangBelanja