import {
  Row,
  Col
} from 'react-bootstrap'
import {
  DateConvert
} from 'utilities'

const InfoItemVertical = ({ title, value }) => (
  <div className='mb-2'>
    <small style={{ fontSize: 12 }} className="text-secondary">{title}</small>
    <div style={{ fontSize: 14 }}>{value}</div>
  </div>
)

export const InfoSection = ({data, type}) => {
  return (
    <>
      <Row>
        <Col xl>
          <Row>
            <Col md>
              <InfoItemVertical 
                title="Tgl. Sales Order"
                value={data?.tgl_sales_order ? DateConvert(new Date(data?.tgl_sales_order)).detail : '-'}  
              />
              <InfoItemVertical 
                title="No. Sales Order"
                value={data?.no_sales_order ?? '-'}
              />
              <InfoItemVertical 
                title="Batas Waktu Pengiriman"
                value={data?.batas_waktu ? DateConvert(new Date(data?.batas_waktu)).detail : '-'}  
              />
            </Col>
            <Col md>
              <InfoItemVertical 
                title="Sales"
                value={data?.nama_sales ?? '-'}
              />
              <InfoItemVertical 
                title="Customer"
                value={data?.nama_customer ?? '-'}
              />
            </Col>
          </Row>
        </Col>
        <Col xl>
          <InfoItemVertical 
            title="Alamat Pengiriman"
            value={data?.alamat_pengiriman ?? '-'}
          />
          <InfoItemVertical 
            title="Catatan Sales Order"
            value={data?.catatan_so ?? data?.catatan ?? '-'}
          />
        </Col>
      </Row>

      {type === 'detail' && (
        <>
          <hr />
          <Row>
            <Col md={8} lg={6}>
              <Row>
                <Col md>
                  <InfoItemVertical 
                    title="Tgl. Faktur Penjualan"
                    value={data?.tgl_faktur ? DateConvert(new Date(data?.tgl_faktur)).detail : '-'}
                    />
                </Col>
                <Col md>
                  <InfoItemVertical 
                    title="No. Faktur Penjualan"
                    value={data?.no_faktur ?? '-'}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}