import { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Card, Row, Col } from "react-bootstrap"
import { useReactToPrint } from "react-to-print"
import {
  BackButton,
  InfoItemVertical,
  DataStatus,
  ActionButton
} from "components"
import { TableKeranjangBelanja } from "./components"
import { PenjualanLangsungApi } from 'api'
import { DateConvert } from 'utilities'
import PrintPenjualanLangsung from './PrintPenjualanLangsung'

const DetailPenjualanLangsung = ({ setNavbarTitle }) => {
  const history = useHistory()
  const refPrint = useRef()
  const { id: id_penjualan_langsung } = useParams()
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [dataInfo, setDataInfo] = useState({})
  const [data, setData] = useState([])

  const reactToPrint = useReactToPrint({ content: () => refPrint.current })
  const handlePrint = () => reactToPrint()

  const getInitialData = () => {
    setIsPageLoading(true)

    PenjualanLangsungApi.getSingle({ id_penjualan_langsung })
      .then(({ data }) => {
        const dataInfo = data?.data ?? {}
        const diskon = parseFloat(dataInfo.diskon) * 100
        const ppn = parseFloat(dataInfo.ppn) * 100

        setDataInfo({ ...dataInfo, diskon, ppn })
        setData(data?.data?.detail?.map(item => ({
          kode_barang: item.kode_item ?? "",
          nama_barang: item.nama_item ?? "",
          nama_gudang: item.nama_gudang ?? "",
          nama_satuan: item.nama_satuan ?? "",
          qty_belanja: item.qty ?? 0,
          harga_terbaru: item.harga ?? 0,
          sub_total: item.sub_total ?? "",
        })) ?? [])
      })
      .finally(() => setIsPageLoading(false))
  }

  useEffect(() => {
    setNavbarTitle("Penjualan Langsung")
    getInitialData()

    return () => { }
  }, [])

  if (isPageLoading) {
    return (
      <>
        <div className="d-flex justify-content-end align-items-center">
          <BackButton onClick={() => history.goBack()} />
        </div>
        <DataStatus loading={true} text="Memuat data . . ." />
      </>
    )
  }

  return (
    <>
      {/* Back Button */}
      <div className="d-flex justify-content-end align-items-center">
        <ActionButton
          className="btn btn-primary text-white mx-1 mb-1"
          size="md"
          text="Cetak"
          onClick={handlePrint} />
        <BackButton onClick={() => history.goBack()} />
      </div>

      <div className="mb-2 h6 font-weight-bold">Detail Data Penjualan Langsung</div>

      {/* Form Card */}
      <Card>
        <Card.Body>
          <Row className="d-flex justify-content-start align-items-center">
            <Col md={3}>
              <InfoItemVertical
                label="Tgl. Penjualan Langsung"
                text={dataInfo?.tgl_penjualan_langsung ? DateConvert(new Date(dataInfo?.tgl_penjualan_langsung)).detail : "-"}
              />
            </Col>

            <Col md={3}>
              <InfoItemVertical
                label="Customer"
                text={dataInfo.nama_customer ?? ""}
              />
            </Col>

            <Col md={3}>
              <InfoItemVertical
                label="Metode Pembayaran"
                text={<span className="text-capitalize">{dataInfo.metode_pembayaran ?? ""}</span>}
              />
            </Col>
          </Row>

          <Row className="d-flex justify-content-start align-items-center">
            <Col md={3}>
              <InfoItemVertical
                label="No. Penjualan Langsung"
                text={dataInfo?.no_penjualan_langsung ?? ""}
              />
            </Col>

            <Col md={3}>
              <InfoItemVertical
                label="Petugas Sales"
                text={dataInfo.nama_karyawan ?? ""}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* Table Keranjang Belanja */}
      <TableKeranjangBelanja type="DETAIL" data={data} setData={setData} dataInfo={dataInfo} />

      <div style={{ display: "none" }}>
        <PrintPenjualanLangsung
          id={id_penjualan_langsung}
          ref={refPrint}
          data={data}
          dataInfo={dataInfo} />
      </div>
    </>
  )
}

export default DetailPenjualanLangsung