import Services from "services"

class FakturPenjualanApi {
  get(params) {
    return Services.get("/faktur_penjualan/page", {params})
  }

  getSingleFaktur(params) {
    return Services.get("/faktur_penjualan/single", {params})
  }

  getSO(params) {
    return Services.get("/faktur_penjualan/list_so", {params})
  }

  getSingleSO(params) {
    return Services.get("/faktur_penjualan/so_single", {params})
  }

  getNomor(params) {
    return Services.get("/faktur_penjualan/no_baru", {params})
  }

  save(data) {
    return Services.post("/faktur_penjualan", data)
  }

  update(data) {
    return Services.put("/faktur_penjualan", data)
  }
}

export default new FakturPenjualanApi();
