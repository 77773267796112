import React, { useEffect, useState } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from "yup"
import {
  CreateModal,
  SelectSearch,
  Input,
  ActionButton,
  TextArea,
} from 'components'
import { PenjualanLangsungApi } from 'api'
import axios from 'axios'

const ModalTambahCustomer = ({ fetchCustomer, modalConfig, setModalConfig }) => {
  const [dropdown, setDropdown] = useState({
    jenis_customer: [],
    provinsi: [],
    kabupaten: [],
    kecamatan: [],
    desa: [],
  })
  const [loading, setLoading] = useState({
    initial: false,
    kabupaten: false,
    kecamatan: false,
    desa: false,
  })

  const formInitialValues = {
    id_jenis_customer: "",
    kode_customer: "",
    nama_customer: "",
    telepon: "",
    id_provinsi: "",
    id_kabupaten: "",
    id_kecamatan: "",
    id_desa: "",
    alamat: ""
  }

  const formValidationSchema = Yup.object().shape({
    id_jenis_customer: Yup.string().required("Jenis Customer diperlukan!"),
    nama_customer: Yup.string().required("Nama Customer diperlukan!"),
    telepon: Yup.string().required("Nomor Telepon diperlukan!"),
    id_provinsi: Yup.string().required("Provinsi diperlukan!"),
    id_kabupaten: Yup.string().required("Kabupaten diperlukan!"),
    id_kecamatan: Yup.string().required("Kecamatan diperlukan!"),
    id_desa: Yup.string().required("Desa diperlukan!"),
    alamat: Yup.string().required("Alamat diperlukan!"),
  })

  const formSubmitHandler = (values, { setSubmitting }) => {
    PenjualanLangsungApi.saveCustomer(values)
      .then(() => {
        setModalConfig({ show: false, type: "" })
        fetchCustomer()

      })
      .catch(() => { })
  }

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit: formSubmitHandler
  })

  const { values, errors, touched, isSubmitting, setFieldValue, setValues, handleChange, handleSubmit } = formik

  const numberOnlyRegex = value => value.toString().replace(/[^0-9\.]/g, "").substring(0, 12)

  const getInitialData = () => {
    setLoading(prev => ({ ...prev, initial: true }))
    axios.all([
      PenjualanLangsungApi.getKodeCustomer(),
      PenjualanLangsungApi.getJenisCustomer(),
      PenjualanLangsungApi.getProvinsi(),
    ])
      .then(axios.spread((kodeCustomer, jenisCustomer, provinsi) => {
        const mapJenisCustomer = jenisCustomer.data.data.map(item => ({ value: item.id_jenis_customer, label: item.nama_jenis_customer }))
        const mapProvinsi = provinsi.data.data.map(item => ({ value: item.id_provinsi, label: item.nama_provinsi }))

        setFieldValue("kode_customer", kodeCustomer.data.data)
        setDropdown(prev => ({
          ...prev,
          jenis_customer: mapJenisCustomer ?? [],
          provinsi: mapProvinsi ?? []
        }))
      }))
      .finally(() => setLoading(prev => ({ ...prev, initial: false })))
  }

  const fetchKabupaten = id_provinsi => {
    setLoading(prev => ({ ...prev, kabupaten: true }))
    PenjualanLangsungApi.getKabupaten({ id_provinsi })
      .then(({ data }) => setDropdown(prev => ({
        ...prev,
        kabupaten: data.data.map(item => ({
          value: item.id_kabupaten,
          label: item.nama_kabupaten
        }))
      })))
      .finally(() => setLoading(prev => ({ ...prev, kabupaten: false })))
  }

  const fetchKecamatan = id_kabupaten => {
    setLoading(prev => ({ ...prev, kecamatan: true }))
    PenjualanLangsungApi.getKecamatan({ id_kabupaten })
      .then(({ data }) => setDropdown(prev => ({
        ...prev,
        kecamatan: data.data.map(item => ({
          value: item.id_kecamatan,
          label: item.nama_kecamatan
        }))
      })))
      .finally(() => setLoading(prev => ({ ...prev, kecamatan: false })))
  }

  const fetchDesa = id_kecamatan => {
    setLoading(prev => ({ ...prev, desa: true }))
    PenjualanLangsungApi.getDesa({ id_kecamatan })
      .then(({ data }) => setDropdown(prev => ({
        ...prev,
        desa: data.data.map(item => ({
          value: item.id_desa,
          label: item.nama_desa
        }))
      })))
      .finally(() => setLoading(prev => ({ ...prev, desa: false })))
  }

  useEffect(() => {
    getInitialData()

    return () => { }
  }, [])

  return (
    <CreateModal
      title="Customer"
      size="md"
      show={modalConfig.show && modalConfig.type === "customer"}
      onHide={() => setModalConfig({ ...modalConfig, show: false })}
    >
      <Modal.Body>
        <Row>
          <Col>
            <SelectSearch
              label="Jenis Customer*"
              placeholder="Pilih Jenis Customer"
              option={dropdown.jenis_customer ?? []}
              loading={loading.initial}
              defaultValue={values.id_jenis_customer ? dropdown.jenis_customer.find(item => item.value === values.id_jenis_customer) : null}
              onChange={(opt) => setFieldValue("id_jenis_customer", opt.value)}
              error={errors.id_jenis_customer && touched.id_jenis_customer}
              errorText={errors.id_jenis_customer && touched.id_jenis_customer ? errors.id_jenis_customer : ""}
            />
          </Col>
          <Col>
            <Input readOnly label="Kode Customer" value={values.kode_customer} />
          </Col>
        </Row>

        <Input
          label="Nama Customer*"
          name="nama_customer"
          placeholder="Pilih Nama Customer"
          value={values.nama_customer}
          onChange={handleChange}
          error={errors.nama_customer && touched.nama_customer}
          errorText={errors.nama_customer && touched.nama_customer ? errors.nama_customer : ""}
        />

        <Input
          label="No. Telepon*"
          placeholder="Masukkan nomor telepon"
          type="text"
          value={values.telepon}
          onChange={(e) => {
            const value = e.target.value
            const convert = numberOnlyRegex(value)
            setFieldValue("telepon", convert)
          }}
          error={errors.nama_customer && touched.nama_customer}
          errorText={errors.nama_customer && touched.nama_customer ? errors.nama_customer : ""}
        />

        <SelectSearch
          label="Provinsi*"
          placeholder="Pilih Provinsi"
          loading={loading.initial}
          option={dropdown.provinsi}
          defaultValue={values.id_provinsi ? dropdown.provinsi.find(item => item.value === values.id_provinsi) : null}
          onChange={(opt) => {
            setValues({
              ...values,
              id_provinsi: opt.value,
              id_kabupaten: "",
              id_kecamatan: "",
              id_desa: ""
            })
            fetchKabupaten(opt.value)
          }}
          error={errors.id_provinsi && touched.id_provinsi}
          errorText={errors.id_provinsi && touched.id_provinsi ? errors.id_provinsi : ""}
        />

        <SelectSearch
          key={`id_kabupaten ${values.id_provinsi}`}
          isDisabled={Boolean(!values.id_provinsi)}
          label="Kabupaten*"
          placeholder="Pilih Kabupaten"
          loading={loading.kabupaten}
          option={dropdown.kabupaten}
          onChange={(opt) => {
            setValues({
              ...values,
              id_kabupaten: opt.value,
              id_kecamatan: "",
              id_desa: ""
            })
            fetchKecamatan(opt.value)
          }}
          error={errors.id_kabupaten && touched.id_kabupaten}
          errorText={errors.id_kabupaten && touched.id_kabupaten ? errors.id_kabupaten : ""}
        />

        <SelectSearch
          key={`id_kecamatan ${values.id_kabupaten}`}
          isDisabled={Boolean(!values.id_kabupaten)}
          label="Kecamatan*"
          placeholder="Pilih Kecamatan"
          option={dropdown.kecamatan}
          onChange={(opt) => {
            setValues({
              ...values,
              id_kecamatan: opt.value,
              id_desa: ""
            })
            fetchDesa(opt.value)
          }}
          error={errors.id_kecamatan && touched.id_kecamatan}
          errorText={errors.id_kecamatan && touched.id_kecamatan ? errors.id_kecamatan : ""}
        />

        <SelectSearch
          key={`id_desa ${values.id_kecamatan}`}
          isDisabled={Boolean(!values.id_kecamatan)}
          label="Desa*"
          placeholder="Pilih Kecamatan"
          option={dropdown.desa}
          onChange={(opt) => setFieldValue("id_desa", opt.value)}
          error={errors.id_desa && touched.id_desa}
          errorText={errors.id_desa && touched.id_desa ? errors.id_desa : ""}
        />

        <TextArea
          label="Alamat*"
          name="alamat"
          values={values.alamat}
          onChange={handleChange}
          rows={5}
          error={errors.alamat && touched.alamat}
          errorText={errors.alamat && touched.alamat ? errors.alamat : ""}
        />
      </Modal.Body>

      <Modal.Footer>
        <ActionButton
          variant="outline-secondary"
          text="Batal"
          onClick={() => setModalConfig({ show: false, type: "" })}
        />
        <ActionButton
          text="Tambah"
          loading={isSubmitting}
          onClick={() => handleSubmit()}
        />
      </Modal.Footer>
    </CreateModal >
  )
}

export default ModalTambahCustomer